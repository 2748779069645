import { Modal, Popover } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFile } from '../../../../app/types/models/i-file'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import FileIcon from '../../../../image_files/icons/FileIcon'
import { PreviewIcon } from '../../../../image_files/icons/PreviewIcon'
import { DownloadIcon } from '../../../../image_files/icons/downloadIcon'
import { FileAttach } from '../../../../shared/FileAttach/FileAttach'
import { downloadFile } from '../../../../utils/helpers/downloadFile'
import { imageExtensions } from '../../../../utils/image-extensions'
import './TasksFiles.css'
import UiPopover from '../../../../shared/ui/popover/UiPopover'
import UiPopoverContent from '../../../../shared/ui/popover/UiPopoverContent'

const FileList = ({
    files,
    title,
    handleSave,
    handleDelete,
}: {
    title?:string
    files: Array<any>
    handleSave?: (file: IFile) => void
    handleDelete?: (id: number) => void
}) => {
    const { t } = useTranslation()
    const [previewOpen, setPreviewOpen] = useState({
        status: false,
        fileName: '',
        file: '',
    })

    console.log('files', files)

    const handleDeleteFile = async (id: number) => {
        handleDelete && handleDelete(id)
    }

    const customRequest = async (options) => {
        const fmData = new FormData()
        fmData.append('file', options.file)
        handleSave && handleSave(options.file)
    }

    const showFilesList = () => {
        if (files && files?.length > 0) {
            return files.map((file, key) => {
                const { type, node } = showValidElement(file)
                return (
                    <UiPopover
                        key={key}
                        placement="top"
                        content={
                            <UiPopoverContent title={file.filename.split('/').at(-1)}/>
                        }
                    >
                        <li>
                            {node}
                            <div className="tasks-files-item-bg" />
                            <div className="tasks-files-item__icon-wrapper">
                                {type === 'image' ? (
                                    <PreviewIcon
                                        fill={'#ffffff'}
                                        className={'tasks-files-item__icon '}
                                        onClick={() =>
                                            setPreviewOpen({
                                                status: true,
                                                file: file.file,
                                                fileName: file.filename
                                                    .split('/')
                                                    .at(-1),
                                            })
                                        }
                                    />
                                ) : (
                                    <DownloadIcon
                                        stroke="transparent"
                                        fill="transparent"
                                        className={
                                            'tasks-files-item__icon tasks-files-item__icon--download'
                                        }
                                        onClick={() =>
                                            downloadFile(
                                                file.file,
                                                file.filename,
                                            )
                                        }
                                    />
                                )}
                                <DeleteIcon
                                    stroke="#ffffff"
                                    className={'tasks-files-item__icon'}
                                    onClick={() => handleDeleteFile(file?.id)}
                                />
                            </div>
                        </li>
                    </UiPopover>
                )
            })
        }
        return <></>
    }

    const showValidElement = (elem: IFile) => {
        const file = elem?.file
        const fileName = elem?.filename || 'default.txt'
        if (imageExtensions?.includes(fileName?.split('.').at(-1))) {
            return {
                type: 'image',
                node: <img src={file} alt="task details image" />,
            }
        } else {
            return {
                type: 'file',
                node: (
                    <FileIcon
                        onClick={undefined}
                        className={'cursor-pointer'}
                    />
                ),
            }
        }
    }

    return (
        <div className="tasks-files">
            <div>
                <FileAttach
                    className="tasks-files-attach-btn"
                    files={null}
                    multiple={true}
                    // buttonTitle={getLocalizedText('upload', t)}
                    title={title ?? <span className='font-14-normal'>{t('upload-file-or-drag-it-here')}</span>}
                    customRequest={customRequest}
                    onChange={() => {}}
                    removeFile={handleDeleteFile}
                />
            </div>
            <ul className="tasks-files-list">{showFilesList()}</ul>
            <Modal
                open={previewOpen.status}
                title={
                    <div className="tasks-files-preview-modal-title">
                        <span>{previewOpen.fileName}</span>
                    </div>
                }
                footer={null}
                onCancel={() =>
                    setPreviewOpen((prev) => ({
                        status: false,
                        fileName: '',
                        file: '',
                    }))
                }
            >
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewOpen.file}
                />
            </Modal>
        </div>
    )
}

export default FileList
