import { asyncActions, slice } from './user-slice'
import * as userSelectors from './selectors'
import * as actions from './actions'

const userActions = {
    ...asyncActions,
    ...slice.actions,
    ...actions,
}

const userReducer = slice.reducer

export { userReducer, userSelectors, userActions }
