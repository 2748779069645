import { useTranslation } from 'react-i18next'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { statusesServices } from '../../services/statuses-services'
import { IPanelsObject } from './Panels'
import { tasksService } from '../../services/tasks-services'
import { setPanels, setPanelsWithoutStatus } from '../../store/panels/actions'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentOrganization } from '../../store/projects/selectors'

export const useGetStatuses = (projectId: number) => {
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const currentOrganization = useSelector(selectCurrentOrganization)
    const [loading, setLoading] = useState(false)

    const fetchStatuses = async () => {
        try {
            setLoading(true)
            const statuses = await statusesServices.getStatuses(projectId)

            if (statuses && statuses.length > 0) {
                const panelsOrder = statuses.map((status) => status.position)
                let panels = {} as IPanelsObject
                for (let i = 0; i < statuses.length; i++) {
                    const status = statuses[i]
                    const { results } = await tasksService.getTasks(projectId, {
                        status: status?.id.toString(),
                        ordering: 'status_position',
                        is_template:false,
                    })
                    const tasks =
                        results && results?.length > 0
                            ? results.map((task) => ({
                                  ...task,
                                  status_position: `${task.status_position}-${task.id}`,
                              }))
                            : []

                    const tasksOrder = tasks.map((task) => task.status_position)

                    panels = {
                        ...panels,
                        [status?.position]: {
                            name:
                                i18n.language === 'ru'
                                    ? status.name_ru
                                    : status.name_en,
                            tasks: tasks,
                            tasksOrder,
                            position: status.position,
                            id: status.id,
                        },
                    }
                }

                if (panelsOrder.length === statuses.length) {
                    dispatch(setPanels({ panels, panelsOrder }))
                }
            }
            await fetchAllTasks()
        } catch (error) {
            errorsHandler(error, t)
        } finally {
            setLoading(false)
        }
    }

    const fetchAllTasks = async () => {
        try {
            const { results } = await tasksService.getTasks(projectId, {
                limit: '10000000000000',
            })
            if (results && results.length > 0) {
                const tasks = results
                    .filter((task) => task?.status === null)
                    .map((val) => ({
                        ...val,
                        status_position: `${val.status_position}-${val.id}`,
                    }))

                if (tasks.length > 0) {
                    const tasksOrder = tasks.map(
                        (item) => item?.status_position,
                    )
                    const panel = {
                        id: 'withoutStatus',
                        name: t('without-status'),
                        position: 'withoutStatus',
                        tasks,
                        tasksOrder,
                    }
                    dispatch(setPanelsWithoutStatus({ panel }))
                }
            }
        } catch (error) {}
    }

    useEffect(() => {
        if (projectId !== undefined && !isNaN(projectId)) fetchStatuses()
    }, [i18n.language, projectId, currentOrganization])

    return { isLoading: loading, refetch: fetchStatuses }
}
