import React, {
    FC,
    memo,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IPopoverMenuItem } from '../../../../app/types/common'
import { LanguageTypes } from '../../../../app/types/enums'
import { ButtonItem } from '../../../../app/types/i-button'
import { IStatus, ITask } from '../../../../app/types/models/task'
import { ISupervisor } from '../../../../app/types/models/user'
import { CopyDataIcon } from '../../../../image_files/icons/CopyDataIcon'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import DescriptionIcon from '../../../../image_files/icons/DescriptionIcon'
import { EditIcon } from '../../../../image_files/icons/EditIcon'
import MessageIcon from '../../../../image_files/icons/MessageIcon'
import { OpenInNewTabIcon } from '../../../../image_files/icons/OpenInNewTabIcon'
import PinIcon from '../../../../image_files/icons/PinIcon'
import SubTaskIcon from '../../../../image_files/icons/SubTasksIcon'
import ModalInlog from '../../../../shared/modalInlog/ModalInlog'
import PopoverMenu from '../../../../shared/popoverMenu/PopoverMenu'
import SpinInLog from '../../../../shared/spin/spin'
import UiPopover from '../../../../shared/ui/popover/UiPopover'
import UiPopoverContent from '../../../../shared/ui/popover/UiPopoverContent'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { selectStatuses } from '../../../../store/tasks/selectors'
import { getCorrectDateFormat } from '../../../../utils/getCorrectDateFormat'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { RoadmapContext } from '../../providers'
import SupervisorWithAssignees from '../creatorWithAssginees/CreatorWithAssignees'
import RoadmapItemPriority from '../roadmapPriority/RoadmapPriority'
import './RoadMapItem.css'
import { selectCurrentOrganization } from '../../../../store/projects/selectors'
import { goToNewTab } from '../../../../utils/urlHelpers'

interface IProps {
    item: ITask
    saveBlockHeight?: (id: number | string, data: number) => void
}

interface IModalData {
    title: string
    titleIcon?: ReactNode
    isOpen: boolean
    text: string
    saveBtnTitle?: string
    onSave?: () => void
}

const initialModalData: IModalData = {
    title: '',
    isOpen: false,
    text: '',
}

const MAX_NAME_SYMBOLS_COUNT = 50

const RoadMapItem: FC<IProps> = ({ item, saveBlockHeight }) => {
    const { t, i18n } = useTranslation()
    const { removeTask, setUpdateTaskModalData } = useContext(RoadmapContext)
    const statuses = useSelector(selectStatuses)
    const [isLoading, setIsLoading] = useState(false)
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)
    const [modalsData, setModalsData] = useState(initialModalData)
    const currentProject = useSelector(selectCurrentProject)
    const copyUrl = window.location.href
    const currentOrganization = useSelector(selectCurrentOrganization)
    const domain = copyUrl.replace(
        /^(https?:\/\/)(?:www\.)?([^\/:]+(:\d+)?).*$/,
        '$1$2',
    )

    const targetUrl = `../${currentProject?.id}/tasks/${
        item?.is_template ? 'templates' : 'items'
    }/${item?.slug}`

    const handleOpenUpdateTaskModal = () => {
        setUpdateTaskModalData({ isOpen: true, task: item })
    }

    const getValidStatusName = (status: IStatus | undefined) => {
        if (status !== undefined && status !== null) {
            if (i18n.language === LanguageTypes.ru) {
                return getValidText(status?.name_ru)
            } else {
                return getValidText(status?.name_en)
            }
        } else {
            return t('without-status')
        }
    }

    const getValidStatus = () => {
        if (typeof item.status === 'number') {
            const targetStatus = statuses.find(
                (el) => el?.id === item?.status?.id,
            )
            return getValidStatusName(targetStatus)
        } else {
            return getValidStatusName(item.status)
        }
    }

    const getValidEndDate = () => {
        if (item?.due_date_end && item.due_date_end?.length > 0) {
            return getCorrectDateFormat({
                date: item?.due_date_end,
                separator: '.',
            })
        } else {
            return t('no-date-has-been-set')
        }
    }

    const menuItems: IPopoverMenuItem[] = [
        {
            id: 1,
            title: t('open-in-new-tab'),
            icon: <OpenInNewTabIcon />,
            onClick: () => {
                goToNewTab(targetUrl)
            },
        },
        {
            id: 2,
            title: t('copy-link'),
            icon: <CopyDataIcon />,
            onClick: () => {
                navigator.clipboard
                    .writeText(
                        `${domain}/scheduler/${currentOrganization}/${currentProject.id}/tasks/${
                            item?.is_template ? 'templates' : 'items'
                        }/${item.slug}`,
                    )
                    .then(() => {
                        showNotify(t('link-copied'))
                        setCloseMenuTrigger((prev) => prev + 1)
                    })
            },
        },
        {
            id: 3,
            title: t('edit-task'),
            icon: <EditIcon />,
            onClick: handleOpenUpdateTaskModal,
        },
        {
            id: 4,
            title: t('delete-task'),
            icon: <DeleteIcon />,
            onClick: () => {
                setModalsData({
                    isOpen: true,
                    text: t('you-realy-want-do-delete-this-task'),
                    titleIcon: <DeleteIcon width={18} height={18} />,
                    title: t('task-deleting'),
                    saveBtnTitle: t('delete'),
                    onSave: deleteITem,
                })
                setCloseMenuTrigger((prev) => prev + 1)
            },
        },
    ]

    const modalBtnList: ButtonItem[] = [
        {
            id: 1,
            titleBtn: t('cancel'),
            className: 'font-14-normal-imp',
            handleBtn: () => setModalsData(initialModalData),
        },
        {
            id: 2,
            type: 'primary',
            titleBtn: modalsData?.saveBtnTitle || t('save'),
            handleBtn: modalsData?.onSave,
        },
    ]

    const deleteITem = async () => {
        setModalsData(initialModalData)
        removeTask(item)
    }

    const getClassByDeadline = () => {
        const now = new Date().getTime()
        const targetDate =
            new Date(getValidText(item?.due_date_end)).getTime() || 0
        if (targetDate >= now) {
            return 'roadmap-item-deadline'
        } else return 'roadmap-item-deadline roadmap-item-deadline-error'
    }

    const showValidTaskName = () => {
        if (item?.name.length > MAX_NAME_SYMBOLS_COUNT) {
            return (
                <UiPopover
                    color="black"
                    content={<UiPopoverContent title={item.name} />}
                >
                    <span>
                        {getValidText(
                            item.name.slice(0, MAX_NAME_SYMBOLS_COUNT) + '...',
                        )}
                    </span>
                </UiPopover>
            )
        } else return <span>{getValidText(item?.name)}</span>
    }

    const id = `roadmap-item-${item.id}`

    const showIconByType = (type: string, key: number) => {
        let icon = <></>
        let isShowPopover = false
        let text = ''
        const getValidStroke = (status) =>
            status ? 'var(--grey-icon-color-disabled' : undefined
        switch (type) {
            case 'comment':
                isShowPopover = item?.comments && item.comments.length > 0
                text = isShowPopover
                    ? `${t('comments')}:${item.comments.length}`
                    : ''
                icon = (
                    <MessageIcon
                        key={key}
                        stroke={getValidStroke(!isShowPopover)}
                    />
                )
                break
            case 'description':
                text = t('description-added')
                isShowPopover =
                    item?.description &&
                    item?.description.length > 0 &&
                    item.description !== '<p><br></p>'
                icon = (
                    <DescriptionIcon
                        key={key}
                        stroke={getValidStroke(!isShowPopover)}
                    />
                )
                break
            case 'file':
                isShowPopover = item?.files && item.files?.length > 0
                text = isShowPopover
                    ? `${t('files')}:${item?.files.length}`
                    : ''
                icon = (
                    <PinIcon
                        key={key}
                        stroke={getValidStroke(!isShowPopover)}
                    />
                )
                break
            case 'subtask':
                isShowPopover = item?.subtasks && item.subtasks?.length > 0
                text = isShowPopover
                    ? `${t('subtasks')}:${item?.subtasks.length}`
                    : ''
                icon = (
                    <SubTaskIcon
                        key={key}
                        fill={getValidStroke(!isShowPopover)}
                        stroke={getValidStroke(!isShowPopover)}
                    />
                )
                break
            default:
                icon = <div className="w-13 h-13" />
        }

        if (isShowPopover) {
            return (
                <UiPopover
                    color={'black'}
                    content={<UiPopoverContent title={text} />}
                    children={
                        <div className="cursor-pointer flex-c-c">{icon}</div>
                    }
                />
            )
        } else return icon
    }

    const showTools = () => {
        const list = ['description', 'comment', 'file']
        if (item?.slug?.split('_').length < 3) {
            list.push('subtask')
        } else {
            list.push('123')
        }
        return list.map((el, i) => showIconByType(el, i))
    }

    useEffect(() => {
        const myDiv = document.getElementById(id)
        saveBlockHeight && saveBlockHeight(id, myDiv.offsetHeight)
    }, [item])

    return (
        <li className="cursor-pointer roadmap-item" id={id}>
            {createPortal(<SpinInLog isLoading={isLoading} />, document.body)}
            <div
                className="roadmap-item-task-data"
                onClick={handleOpenUpdateTaskModal}
            >
                <RoadmapItemPriority priority={getValidText(item?.priority)} />
                <div className="roadmap-item-data ">
                    <div className={getClassByDeadline()}>
                        <span>{getValidEndDate()}</span>
                    </div>
                </div>
                <div className="roadmap-item-slug">
                    <div>{getValidText(item?.slug)}</div>
                </div>
                <div className="roadmap-item-data-task-name">
                    {showValidTaskName()}
                </div>
                <SupervisorWithAssignees
                    supervisor={item?.supervisor as ISupervisor}
                    assignees={item?.doers}
                />
                <div className="roadmap-item-status">
                    <span className="dotted-text-100">{getValidStatus()}</span>
                </div>
                <div className="roadmap-item-tools">
                    {showTools()}

                    <PopoverMenu
                        items={menuItems}
                        closeTrigger={closeMenuTrigger}
                        className="roadmap-item-menu"
                    />
                </div>
            </div>

            {createPortal(
                <ModalInlog
                    width={400}
                    open={modalsData.isOpen}
                    title={modalsData.title}
                    listButton={modalBtnList}
                    iconTitle={modalsData?.titleIcon}
                    onCancel={() => setModalsData(initialModalData)}
                >
                    <div className="font-14-normal roadmap-item-modal-inner">
                        {modalsData?.text}
                    </div>
                </ModalInlog>,
                document.body,
            )}
        </li>
    )
}
export default memo(RoadMapItem)
