import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { useGetMembers } from '../../hooks/use-get-members'
import { AccordionArrow } from '../../image_files/icons/AccordionArrow'
import PanelMembersIcon from '../../image_files/icons/PanelMembersIcon'
import MembersBlock from '../../shared/membersBlock/MembersBlock'
import UiPopover from '../../shared/ui/popover/UiPopover'
import UiPopoverContent from '../../shared/ui/popover/UiPopoverContent'
import UISelect from '../../shared/ui/select/UlSelect'
import { setSchedulerPage } from '../../store/app/actions'
import { selectSchedulerPage } from '../../store/app/selectors'
import {
    currentProjectActions,
    currentProjectSelectors,
} from '../../store/currentProject'
import { setProject } from '../../store/currentProject/actions'
import { membersSelectors } from '../../store/members'
import { getOrganizations } from '../../store/organization/selectors'
import { projectsActions, projectsSelectors } from '../../store/projects'
import { selectCurrentOrganization } from '../../store/projects/selectors'
import { SchedulerPagesType } from '../../widgets/scheduler/types'
import './SchedulerMenu.css'
import SchedulerMenuFeatures from './SchedulerMenuFeatures'

const SchedulerMenu= () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const schedulerPage = useSelector(selectSchedulerPage)
    const [collapsed, setCollapsed] = useState(false)
    const { refetch: fetchMembers } = useGetMembers()

    const projects = useSelector(projectsSelectors.selectProjects)
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )
    const organizations = useSelector(getOrganizations) ?? []
    const members = useSelector(membersSelectors.selectMembers)
    const currentOrganization = useSelector(selectCurrentOrganization)

    const handleCollapseStatus = () => {
        setCollapsed(!collapsed)
    }

    const transformSelectedOption = (label) => {
        if (label && label.length > 0) {
            return label[0].toUpperCase()
        } else return ''
    }

    const handleChangeProject = (id:number) => {
        dispatch(
            currentProjectActions.getDetailProject({
                id,
                onSuccess: (res) => {
                    if (res) {
                        navigate(`${currentOrganization}/${id}/${schedulerPage}`)
                    }
                },
            }),
        )
    }

    const handleChangeOrganization = (id: number | string) => {
        dispatch(
            projectsActions.getProjects({
                params: {
                    organization: id,
                },
                onSuccess: (res) => {
                    if (res && res.length > 0) {
                        dispatch(setProject({ project: res[0] }))
                        navigate(`/scheduler/${id}/${res[0]?.id}/tasks`)
                        dispatch(setSchedulerPage(SchedulerPagesType.tasks))
                    }
                },
            }),
        )
        
    }

    useEffect(() => {
        if (Number(currentProject?.id)) {
            fetchMembers(currentProject?.id)
        }
    }, [currentProject?.id])

    return (
        <div
            className={`scheduler__menu ${
                collapsed ? 'scheduler__menu-collapsed' : ''
            }`}
        >
            <div
                className={`${
                    collapsed ? 'm-0-auto' : ''
                } scheduler-accordion__arrow`}
                onClick={handleCollapseStatus}
            >
                <AccordionArrow
                    style={
                        collapsed
                            ? { transform: 'rotate(90deg)' }
                            : { transform: 'rotate(-90deg)' }
                    }
                    className={'scheduler-accordion__arrowIcon'}
                />
            </div>
            {collapsed ? (
                <UiPopover
                    color="white"
                    placement="bottomLeft"
                    content={
                        <UiPopoverContent
                            handleClickItem={(e) =>
                                handleChangeOrganization(e?.value)
                            }
                        />
                    }
                    defaultValue={organizations[0]?.id}
                    options={organizations.map((el) => ({
                        label: el?.fullName,
                        value: el?.id,
                    }))}
                    transformSelectedOption={transformSelectedOption}
                />
            ) : (
                <div className="mt-10">
                    <UISelect
                        label={t('organization')}
                        value={currentOrganization}
                        options={organizations.map((el) => ({
                            label: el.fullName,
                            value: el?.id,
                        }))}
                        onChange={(e) => handleChangeOrganization(e)}
                    />
                </div>
            )}
            {collapsed ? (
                <UiPopover
                    options={projects.map((el) => ({
                        label: el?.name,
                        value: el?.id,
                    }))}
                    color='white'
                    content={
                        <UiPopoverContent
                            handleClickItem={(e) =>
                                handleChangeProject(Number(e?.value))
                            }
                        />
                    }
                    placement="bottomLeft"
                    defaultValue={currentProject?.id}
                    transformSelectedOption={transformSelectedOption}
                />
            ) : (
                <div className="mt-10">
                    <UISelect
                        label={t('project')}
                        value={currentProject?.id}
                        options={projects.map((el) => ({
                            label: el.name,
                            value: el?.id,
                        }))}
                        onChange={handleChangeProject}
                    />
                </div>
            )}
            {collapsed ? (
                <UiPopover
                    placement="bottomLeft"
                    content={
                        <UiPopoverContent
                            title={
                                <>
                                    {members.map((el, i) => (
                                        <div key={i}>{el?.user?.email}</div>
                                    ))}
                                </>
                            }
                        />
                    }
                    children={
                        <div className="mt-10 cursor-pointer flex-c-c">
                            <PanelMembersIcon className="flex-shrink-0" />
                        </div>
                    }
                />
            ) : (
                <MembersBlock members={members} />
            )}
            <SchedulerMenuFeatures
                collapsed={collapsed}
            />
        </div>
    )
}

export default SchedulerMenu
