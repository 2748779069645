import { ITasksFilterParams } from '../../app/types/models/task'

export const initialFilterData: ITasksFilterParams = {
    doers__user: undefined,
    name__icontains: undefined,
    priority: undefined,
    status: undefined,
    slug__icontains: undefined,
    tags: undefined,
    supervisor__user: undefined,
    ordering:'created_at'
}

