import { ACCESS_TOKEN } from '../utils/constants'
import { AxiosResponse } from 'axios'
import { IStatus } from '../app/types/models/task'
import {instance} from "../app/api";

export const statusesServices = {
    getStatuses: async (id: number) => {
        return await instance
            .get<any, AxiosResponse<IStatus[]>>(
                `projects/${id}/tasks/status/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    updateStatus: async (projectId, statusId, params) => {
        return await instance.patch(
            `projects/${projectId}/tasks/status/${statusId}/`,
            { ...params },
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
    createStatus: async (projectId, params) => {
        return await instance.post(
            `projects/${projectId}/tasks/status/`,
            { ...params },
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        ).then(res=>res.data)
    },
    deleteStatus: async (projectId, statusId) => {
        return await instance.delete(
            `projects/${projectId}/tasks/status/${statusId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
}
