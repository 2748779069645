import { ACCESS_TOKEN } from '../utils/constants'
import { AxiosResponse } from 'axios'
import { IMember } from '../app/types/models/user'
import {instance} from "../app/api";

export const membersService = {
    getMembers: async (id) => {
        return await instance
            .get<any, AxiosResponse<IMember[]>>(`/projects/${id}/members/`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            })
            .then((res) => res.data)
    },
}
