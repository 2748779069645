import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { authService } from '../../services/auth-service'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { IUser } from '../../app/types/models/user'
import { showNotice } from '../../utils/helpers/showNotice'
import { useSelector } from 'react-redux'
import { userSelectors } from '../../store/user'

interface IProps {
    setLoading?: (value: boolean) => void
}

const PasswordBlock: FC<IProps> = ({ setLoading }) => {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.selectUser)

    const changePassword = (e) => {
        e.preventDefault()
        setLoading && setLoading(true)
        authService
            .passwordReset({ email: user.email })
            .then((res) => {
                if (res.detail) {
                    showNotice('mail-to-change-password-has-been-sent', t)
                    setLoading && setLoading(false)
                }
            })
            .catch((e) => {
                errorsHandler(e, t)
                setLoading && setLoading(false)
            })
    }

    return (
        <div>
            <form>
                <div className="change_password_box br-5">
                    <div className="change_password_header_box change_password_data_box">
                        <h2 className="font-22-normal">{`${t('password')}`}</h2>
                    </div>
                    <div className="change_password_input_box change_password_data_box">
                        <p className="font-16-normal">
                                <Trans
                                    i18nKey="tap-link-to-change-password" // optional -> fallbacks to defaults if not provided
                                    defaults="Если вы хотите изменить свой пароль, пожалуйста, перейдите по <span id='password-tap-link'>ссылке</span>" // optional defaultValue
                                    components={{
                                        span: (
                                            <span
                                                onClick={changePassword}
                                                id="password-tap-link"
                                                className='font-link'
                                            />
                                        ),
                                    }}
                                />
                        </p>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PasswordBlock
