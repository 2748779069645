import { ConfigProvider, DatePicker, Modal } from 'antd'
import enUS from 'antd/es/locale/en_US'
import ruRU from 'antd/es/locale/ru_RU'
import moment from 'moment'
import React, {
    FC,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DATE_FORMAT, DATE_FORMAT_TO_SERVER } from '../../app/constants'
import { ISelectOption } from '../../app/types/common'
import { PriorityTypes } from '../../app/types/enums'
import {
    ITag,
    ITask,
    ITaskFieldsForUpdates,
} from '../../app/types/models/task'
import { RoadmapContext } from '../../features/roadMap/providers'
import useDebounce from '../../hooks/useDebounce'
import { MembersItem } from '../../shared/MembersItem/MembersItem'
import { PrioritySelect } from '../../shared/PrioritySelect/PrioritySelect'
import { TagsDropdown } from '../../shared/TagsDropdown/TagsDropdown'
import { stopEvent } from '../../shared/stopEvent'
import TaskNameEditBlock from '../../shared/taskNameEditBlock/TaskNameEditBlock'
import UISelect from '../../shared/ui/select/UlSelect'
import { membersSelectors } from '../../store/members'
import { selectPanels } from '../../store/panels/selectors'
import { selectStatuses } from '../../store/tasks/selectors'
import './EditTaskModal.css'
import RoadMapUpdateTaskTabs from './RoadMapUpdateTaskTabs'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { tasksActions } from '../../store/tasks'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'

interface IProps {
    // task?: ITask
    type?: 'task' | 'panel-task' | 'template'
    data: ITaskFieldsForUpdates
    title?: string
    projectId: number
    isOpen: boolean
    className?: string
    panelPosition?: number
    closeModal?: () => void
}

const EditTaskModal: FC<IProps> = (props) => {
    const {
        data,
        isOpen,
        type = 'task',
        title,
        projectId,
        panelPosition,
        closeModal,
    } = props
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    const locale = i18n.language === 'ru' ? ruRU : enUS
    const { setTagsOptions } = useContext(RoadmapContext)
    const panels = useSelector(selectPanels)
    const statuses = useSelector(selectStatuses)
    const [statusOptions, setStatusesOptions] = useState<ISelectOption[]>([])
    const [membersOptions, setMembersOptions] = useState<ISelectOption[]>([])
    const members = useSelector(membersSelectors.selectMembers)

    const getDoersIds = (data: any) => {
        if (data && data.doers) {
            return data.doers.map((el) => el?.user?.id)
        } else return []
    }

    const [fieldsData, setFieldsData] = useState({
        ...data,
        doers: getDoersIds(data?.doers ?? []),
        supervisor: data?.supervisor?.user?.id,
        status: data?.status?.id,
    })
    const debouncedName = useDebounce(fieldsData.name, 500)

    const changeTemplate = (template: ITask, data: ITaskFieldsForUpdates) => {
        const resultTask = {
            ...template,
            ...data,
            project: projectId,
            is_template: type === 'template',
        } as any

        if (
            data.doers !== undefined ||
            data.supervisor !== undefined ||
            data.comment !== undefined ||
            data.file !== undefined ||
            data.deletedFile !== undefined ||
            data.sub_task_data !== undefined
        ) {
            if (data.doers) {
                dispatch(
                    tasksActions.changeTaskDoers({
                        idList: data.doers,
                        projectId: projectId,
                        task: template,
                        panelPosition,
                        onSuccess: (res) => {
                            setFieldsData((prev) => ({
                                ...prev,
                                doers: getDoersIds(res),
                            }))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
            if (data.supervisor) {
                dispatch(
                    tasksActions.changeTaskSupervisor({
                        projectId: projectId,
                        id: resultTask.supervisor,
                        task: resultTask,
                        panelPosition,
                        onSuccess: (res) => {
                            setFieldsData((prev) => ({
                                ...prev,
                                supervisor: res?.supervisor?.user?.id,
                            }))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
            if (data.comment) {
                dispatch(
                    tasksActions.addNewComment({
                        projectId: projectId,
                        slug: resultTask?.slug,
                        value: data.comment,
                        panelPosition,
                        onSuccess: (res) => {
                            setFieldsData((prev) => ({
                                ...prev,
                                comments: prev?.comments
                                    ? [...prev.comments, res]
                                    : [res],
                            }))
                        },
                    }),
                )
            }
            if (data.file) {
                dispatch(
                    tasksActions.addTaskFiles({
                        projectId: projectId,
                        slug: resultTask?.slug,
                        file: data?.file,
                        panelPosition,
                        onSuccess: (res) => {
                            setFieldsData((prev) => ({
                                ...prev,
                                files: prev?.files
                                    ? [...prev.files, res]
                                    : [res],
                            }))
                        },
                    }),
                )
            }
            if (data.deletedFile) {
                dispatch(
                    tasksActions.deleteTaskFile({
                        projectId: projectId,
                        slug: resultTask?.slug,
                        fileId: data.deletedFile,
                        panelPosition,
                        onSuccess: () => {
                            setFieldsData((prev) => ({
                                ...prev,
                                files: prev?.files?.filter(
                                    (el) => el?.id !== data.deletedFile,
                                ),
                            }))
                        },
                    }),
                )
            }
            if (data.sub_task_data) {
                if (data.sub_task_data.type === 'add-subtask') {
                    dispatch(
                        tasksActions.addNewSubtask({
                            projectId: projectId,
                            taskId: template?.id,
                            value: data.sub_task_data.value,
                            is_template: type === 'template',
                            onSuccess: (res) => {
                                setFieldsData((prev) => ({
                                    ...prev,
                                    ...prev,
                                    subtasks: prev?.subtasks
                                        ? [...prev?.subtasks, res]
                                        : [res],
                                }))
                            },
                        }),
                    )
                }
                if (data.sub_task_data.type === 'change-subtask-title') {
                    dispatch(
                        tasksActions.changeSubtaskTitle({
                            projectId: projectId,
                            parentTaskId: resultTask?.id,
                            value: data.sub_task_data.value,
                            slug: data.sub_task_data?.slug,
                            onSuccess: (res) => {
                                setFieldsData((prev) => ({
                                    ...prev,
                                    subtasks: prev?.subtasks
                                        ? prev?.subtasks.map((el) =>
                                              el?.slug ===
                                              prev.sub_task_data?.slug
                                                  ? res
                                                  : el,
                                          )
                                        : [res],
                                }))
                            },
                        }),
                    )
                }
                if (data.sub_task_data.type === 'delete-subtask') {
                    dispatch(
                        tasksActions.removeSubtask({
                            projectId: projectId,
                            parentTaskSlug: resultTask?.slug,
                            slug: data.sub_task_data?.slug,
                            onSuccess: () => {
                                setFieldsData((prev) => ({
                                    ...prev,
                                    subtasks: prev?.subtasks
                                        ? prev?.subtasks.filter(
                                              (el) =>
                                                  el?.slug !==
                                                  data.sub_task_data?.slug,
                                          )
                                        : [],
                                }))
                            },
                        }),
                    )
                }
            }
        } else {
            if (data && Object.keys(data).length > 0) {
                dispatch(
                    tasksActions.changeTaskFields({
                        task: resultTask,
                        data: { ...data, is_template: type === 'template' },
                        panelPosition,
                        onSuccess: (res) => {
                            setFieldsData((prev) => ({
                                ...prev,
                                res,
                                supervisor: res?.supervisor?.user?.id,
                                doers: getDoersIds(res),
                                status: res?.status?.id,
                            }))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
        }
    }

    //change fields
    const handleChangeField = (params: ITaskFieldsForUpdates) => {
        changeTemplate(data as any, params)
    }

    const handleChangeRate = (value: any[]) => {
        if (Array.isArray(value)) {
            const due_date_start = moment(new Date(value[0])).format(
                DATE_FORMAT_TO_SERVER,
            )
            const due_date_end = moment(new Date(value[1])).format(
                DATE_FORMAT_TO_SERVER,
            )
            handleChangeField({ due_date_start, due_date_end })
        }
    }

    const changeSupervisor = (id?: number) => {
        id !== undefined && handleChangeField({ supervisor: id })
    }

    const changeDoers = async (idList?: number[]) => {
        idList && handleChangeField({ doers: idList })
    }

    const handleCreateTag = (res) => {
        if (res) {
            setTagsOptions((prev) => [
                ...prev,
                { label: res?.name, value: res?.id },
            ])
        }
    }

    const getMembersOptions = () => {
        if (members && members?.length > 0) {
            setMembersOptions(
                members.map((m) => ({
                    label: (
                        <MembersItem
                            member={m}
                            containerClassName="roadmap-filter-member-select-option"
                        />
                    ),
                    value: m?.user?.id,
                })),
            )
        }
    }

    // const getValidTagsOptions = (tags: ITag[] | undefined): number[] => {
    //     if (tags && tags?.length > 0) {
    //         return tags.map((el) => {
    //             if (typeof el !== 'number') {
    //                 return el?.id
    //             } else return el
    //         })
    //     } else return []
    // }

    useEffect(() => {
        if (statuses && statuses?.length > 0 && panelPosition === undefined) {
            setStatusesOptions(
                statuses?.map((el) => ({
                    label: i18n.language === 'en' ? el?.name_en : el?.name_ru,
                    value: el?.id,
                })),
            )
        }
    }, [statuses, i18n])

    useEffect(() => {
        getMembersOptions()
    }, [members])

    useEffect(() => {
        if (debouncedName?.length > 0 && debouncedName !== data?.name) {
            handleChangeField({ name: debouncedName })
        }
    }, [debouncedName])

    useEffect(() => {
        if (type === 'template' && panels) {
            const list: ISelectOption[] = []
            for (let key in panels) {
                const item = panels[key]
                list.push({
                    value: item?.id,
                    label: item?.name,
                })
                setStatusesOptions(list)
            }
        }
    }, [panelPosition, panels])

    console.log('fieldsData', fieldsData)

    return (
        <div className="roadmap-edit-task-block" onClick={stopEvent}>
            {createPortal(
                <Modal
                    footer={null}
                    open={isOpen}
                    title={title}
                    className="roadmap-add-task-modal edit-task-modal"
                    onCancel={() => {
                        closeModal && closeModal()
                    }}
                >
                    <div>
                        <div className="roadmap-add-task-modal-settings">
                            <TaskNameEditBlock
                                value={fieldsData.name}
                                slug={data?.slug}
                                projectId={projectId}
                                onChange={(name) => {
                                    handleChangeField({ name })
                                }}
                            />
                            <div className="roadmap-add-task-modal-items-wrapper">
                                <div>
                                    {type !== 'template' && (
                                        <>
                                            <div className="roadmap-filter-field-wrap">
                                                <PrioritySelect
                                                    label={t('priority')}
                                                    onChange={(e) =>
                                                        handleChangeField({
                                                            priority: e,
                                                        })
                                                    }
                                                    currentPriority={
                                                        fieldsData.priority as PriorityTypes
                                                    }
                                                />
                                            </div>
                                            <div className="roadmap-filter-field-wrap">
                                                <UISelect
                                                    label={t('status')}
                                                    options={statusOptions}
                                                    value={fieldsData?.status}
                                                    onChange={(e) =>
                                                        handleChangeField({
                                                            status: e,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}

                                    <>
                                        <div className="roadmap-filter-field-wrap">
                                            <div className="font-14-normal">
                                                {t('deadlines')}
                                            </div>
                                            <ConfigProvider locale={locale}>
                                                <DatePicker.RangePicker
                                                    allowClear
                                                    format={DATE_FORMAT}
                                                    value={[
                                                        moment(
                                                            fieldsData?.due_date_start,
                                                        ),
                                                        moment(
                                                            fieldsData?.due_date_end,
                                                        ),
                                                    ]}
                                                    className="datePicker-item"
                                                    onChange={handleChangeRate}
                                                />
                                            </ConfigProvider>
                                        </div>
                                        <div className="roadmap-filter-field-wrap">
                                            <div className="font-14-normal">
                                                {t('tags')}
                                            </div>
                                            <TagsDropdown
                                                projectId={projectId}
                                                taskId={data?.id}
                                                slug={data?.slug}
                                                tags={data?.tags}
                                                createTag={handleCreateTag}
                                            />
                                        </div>
                                    </>
                                </div>
                                <div>
                                    {type !== 'template' && (
                                        <>
                                            <div className="roadmap-add-task-modal-members">
                                                <div className="roadmap-filter-field-wrap">
                                                    <UISelect
                                                        label={t('supervisor')}
                                                        options={membersOptions}
                                                        value={
                                                            fieldsData?.supervisor
                                                        }
                                                        onChange={
                                                            changeSupervisor
                                                        }
                                                    />
                                                </div>
                                                <div className="roadmap-filter-field-wrap">
                                                    <UISelect
                                                        label={t('assignee')}
                                                        options={membersOptions}
                                                        mode="multiple"
                                                        value={
                                                            fieldsData?.doers
                                                        }
                                                        onChange={changeDoers}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <RoadMapUpdateTaskTabs
                            fields={{
                                id: data?.id,
                                slug: data?.slug,
                                project: projectId,
                                ...fieldsData,
                            }}
                            type={type}
                            projectId={projectId}
                            onChange={handleChangeField}
                            panelPosition={panelPosition}
                        />
                    </div>
                </Modal>,
                document.body,
            )}
        </div>
    )
}

export default EditTaskModal
