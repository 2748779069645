import { RootState } from '../store'
import { UserDTO } from '../../app/types/dto/user.dto'
import { LanguageTypes } from '../../app/types/enums'
import { RequestStatusType } from '../../app/types/request'
import { IUser } from '../../app/types/models/user'

export const selectUser = (state: RootState): IUser | null =>
    state.user.user

export const selectLanguage = (state: RootState): LanguageTypes =>
    state.user.language

export const selectUserStatus = (state: RootState): RequestStatusType =>
    state.user.userStatus
export const selectUserFilesStatus = (state: RootState): RequestStatusType =>
    state.user.filesStatus
