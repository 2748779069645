import { createAction } from '@reduxjs/toolkit'
import { IMember } from '../../app/types/models/user'

const setMembers = createAction<{ members: IMember[] }>(
    'membersActions/setMembers',
)

const setStorageMembers = createAction<{ members: IMember[] }>(
    'membersActions/setStorageMembers',
)

export { setMembers, setStorageMembers }
