export const POPOVER_MARGIN = 10
export const TASKS_LIMIT = 20
export const TASKS_LIMIT_MAX = 25

export const EDITABLE_NAME_SYMBOLS_MAX_LENGTH = 76


export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_FORMAT_TO_SERVER = 'YYYY-MM-DD'

export const MIN_SEARCH_LIST_LENGTH = 3