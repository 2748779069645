import { UserLogo } from '../UserLogo/UserLogo'
import { IMember } from '../../app/types/models/user'
import React, { FC } from 'react'
import {getFirstMemberCharacter} from "../../utils/helpers/getFirstMemberCharacter";

interface IProps {
    members: IMember[]
    className?: string
}

const MembersBlock: FC<IProps> = ({ members, className }) => {
    const makeBlocksLeftShift = (i) => {
        if (i > 0) {
            return `translateX(-${5 * i}px)`
        } else return ''
    }
    return (
        <div className={`mt-10 flex ${className ? className : ''}`}>
            {members &&
                members.map((member, i) => {
                    return (
                        <UserLogo
                            key={i}
                            value={getFirstMemberCharacter(member)}
                            title={member.user?.full_name}
                            member={member}
                            color={member.color}
                            zIndex={i + 1}
                            leftShift={makeBlocksLeftShift(i)}
                        />
                    )
                })}
        </div>
    )
}

export default MembersBlock
