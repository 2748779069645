import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { LanguageTypes } from '../../app/types/enums'
import { IUser } from '../../app/types/models/user'
import { RequestStatusType } from '../../app/types/request'
import { userService } from '../../services/user-service'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import {
    setLanguage,
    setUser,
    setUserFilesStatus,
    setUserStatus,
} from './actions'

export interface InitialState {
    user: IUser | null
    language?: LanguageTypes
    userStatus: RequestStatusType
    filesStatus: RequestStatusType
}

const initialState: InitialState = {
    user: null,
    language: LanguageTypes.ru,
    userStatus: 'idle',
    filesStatus: 'idle',
}

const getUser = createAsyncThunk<
    void,
    {
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>('tasks/getTasksAsync', async ({ onSuccess, onError }, thunkAPI: any) => {
    try {
        thunkAPI.dispatch(setUserStatus('loading'))
        const user = await userService.fetchUser()
        thunkAPI.dispatch(setUser({ user }))
        thunkAPI.dispatch(setUserStatus('succeeded'))
        onSuccess && onSuccess()
    } catch (e) {
        onError && onError(e)
        thunkAPI.dispatch(setUserStatus('failed'))
        return handleAsyncServerNetworkError(e, thunkAPI)
    }
})

const updateUser = createAsyncThunk<
    void,
    {
        data: FormData
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/updateUserAsync',
    async ({ data, onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setUserStatus('loading'))
            const response = await userService.updateUser(data)
            thunkAPI.dispatch(setUser({ user: response }))
            thunkAPI.dispatch(setUserStatus('succeeded'))
            onSuccess && onSuccess(response)
        } catch (e) {
            onError && onError(e)
            thunkAPI.dispatch(setUserStatus('failed'))
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const getUserDocuments = createAsyncThunk<
    void,
    {
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/getUserDocumentsAsync',
    async ({ onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setUserFilesStatus('loading'))

            const res = await userService.getUserDocuments()

            if (res && res.length > 0) {
                const user = thunkAPI.getState()?.user?.user
                thunkAPI.dispatch(
                    setUser({ user: { ...user, files: [...res] } }),
                )
            }
            thunkAPI.dispatch(setUserFilesStatus('succeeded'))
            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            thunkAPI.dispatch(setUserFilesStatus('failed'))
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)
const addUserDocument = createAsyncThunk<
    void,
    {
        file: any
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/addUserDocumentAsync',
    async ({ file, onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setUserFilesStatus('loading'))
            const fmData = new FormData()
            fmData.append('file', file)

            const res = await userService.addUserDocument(fmData)

            if (res) {
                const user = thunkAPI.getState()?.user?.user
                thunkAPI.dispatch(
                    setUser({
                        user: {
                            ...user,
                            files: user?.files ? [...user.files, res] : [res],
                        },
                    }),
                )
            }
            thunkAPI.dispatch(setUserFilesStatus('succeeded'))
            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            thunkAPI.dispatch(setUserFilesStatus('failed'))
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)
const deleteUserDocument = createAsyncThunk<
    void,
    {
        id: number
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/deleteUserDocumentAsync',
    async ({ id, onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setUserFilesStatus('loading'))
            await userService.deleteUserDocument(id)

            const user = thunkAPI.getState()?.user?.user
            thunkAPI.dispatch(
                setUser({
                    user: {
                        ...user,
                        files: user?.files
                            ? user.files.filter((file) => file.id !== id)
                            : [],
                    },
                }),
            )
            thunkAPI.dispatch(setUserFilesStatus('succeeded'))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            thunkAPI.dispatch(setUserFilesStatus('failed'))
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

export const asyncActions = {
    getUser,
    updateUser,
    getUserDocuments,
    addUserDocument,
    deleteUserDocument,
}

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setUser, (state, action) => {
            state.user = action.payload.user
        })
        builder.addCase(setLanguage, (state, action) => {
            state.language = action.payload.language
        })
        builder.addCase(setUserStatus, (state, action) => {
            state.userStatus = action.payload
        })
        builder.addCase(setUserFilesStatus, (state, action) => {
            state.filesStatus = action.payload
        })
    },
})
