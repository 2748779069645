import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addTemplate, changeTemplate, deleteTemplate, setTemplates, setTemplatesStatus } from "./actions"
import { ITasksFilterParams } from "../../app/types/models/task"
import { AxiosError } from "axios"
import { setAppStatus } from "../app/actions"
import { tasksService } from "../../services/tasks-services"
import { handleAsyncServerNetworkError } from "../../utils/helpers/errors/handleAsyncError"
import { templatesService } from "../../services/templates-service"
import { RequestStatusType } from "../../app/types/request"
import { PriorityTypes } from "../../app/types/enums"
import { IAddTemplateDTO, IUpdateTemplateDTO } from "../../app/types/dto/templates.dto"

export interface InitialState {
    count: number
    next: null | number | string
    previous: null | number | string
    results: any[]
    status: RequestStatusType
}


const initialState: InitialState = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    status:'idle'
}

const getTemplates = createAsyncThunk<
    void,
    {
        projectId: number,
        params?:ITasksFilterParams
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'templates/getTemplatesAsync',
    async ({ projectId,params, onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setTemplatesStatus('loading'))
            const res = await templatesService.getTemplates(
                projectId,
                params,
            )
            thunkAPI.dispatch(setTemplates(res))
            thunkAPI.dispatch(setTemplatesStatus('succeeded'))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            thunkAPI.dispatch(setTemplatesStatus('failed'))
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const getTemplate = createAsyncThunk<
    void,
    {
        projectId:number
        templateId: number,
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'templates/getTemplateAsync',
    async ({ projectId,templateId, onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            const res = await templatesService.getTemplate(
                projectId,
                templateId,
            )
            thunkAPI.dispatch(changeTemplate(res))
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const createTemplate = createAsyncThunk<
    void,
    {
        projectId: number
        template:{
            name:string,
            priority?:PriorityTypes,
            description?:string,
            due_date_start?:string
            due_date_end?:string
            status?:number
        }
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'templates/createTemplateAsync',
    async ({ projectId, template, onSuccess, onError }, thunkAPI: any) => {
        try {
            const templates = thunkAPI.getState().templates.results

            const body = {...template} as IAddTemplateDTO

            if(templates.length>0){
                const lastSlug = templates[0]?.slug || ''

                const taskNumber = Number(lastSlug.split('_')[1]) || 1
                body.slug = `TMP_${taskNumber+1}`
            }else{
                body.slug = `TMP_1`
            }

            const res =await templatesService.createTemplate(body, projectId)
            thunkAPI.dispatch(addTemplate(res))
            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const updateTemplate = createAsyncThunk<
    void,
    {
        projectId: number
        template:IUpdateTemplateDTO
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'templates/updateTemplateAsync',
    async ({ projectId, template, onSuccess, onError }, thunkAPI: any) => {
        try {
            const res =await templatesService.updateTemplate(template, projectId,template?.id)
            thunkAPI.dispatch(changeTemplate(res))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)
const removeTemplate = createAsyncThunk<
    void,
    {
        projectId: number
        templateId:number
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'templates/removeTemplateAsync',
    async ({ projectId, templateId, onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            await templatesService.deleteTemplate(projectId,templateId)
            thunkAPI.dispatch(deleteTemplate(templateId))
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)


export const asyncActions = {
    getTemplates,createTemplate,updateTemplate,removeTemplate,getTemplate
}

export const slice = createSlice({
    name: 'templates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setTemplates, (state, action) => {
            state.count = action.payload.count
            state.next = action.payload.next
            state.previous = action.payload.previous
            state.results = action.payload.results
        })
        builder.addCase(addTemplate, (state, action) => {
            state.results = [action.payload, ...state.results]
            state.count = state.count + 1
        })
        builder.addCase(changeTemplate, (state, action) => {
            state.results = state.results.map((template) => {
                if (template.slug === action.payload.slug) {
                    return { ...template, ...action.payload }
                }

                return template
            })
        })
        builder.addCase(deleteTemplate, (state, action) => {
            state.results = state.results.filter(
                (f) => f.id !== action.payload,
            )
            state.count = state.count - 1
        })
        builder.addCase(setTemplatesStatus, (state, action) => {
            state.status = action.payload
        })
    }
})