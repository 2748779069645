import { ConfigProvider, DatePicker, DatePickerProps } from 'antd'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import enEN from 'antd/es/locale/en_US'
import ruRU from 'antd/es/locale/ru_RU'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import './DatePicker.css';
import { DATE_FORMAT } from '../../../app/constants'

type IProps = DatePickerProps & {
    label?: string | ReactNode
    ref?: any
}

const UiDatePicker = (props: IProps) => {
    const { i18n } = useTranslation()
    return (
        <div className="field-wrap">
            {props?.label && (
                <div className="font-14-normal field-wrap-label">
                    {props?.label}
                </div>
            )}

            <ConfigProvider locale={i18n.language === 'ru' ? ruRU : enEN}>
                <div className="date-picker-wrapper">
                    <DatePicker
                        {...props}
                        format={DATE_FORMAT}
                        locale={i18n.language === 'ru' ? ru_RU : en_EN}
                    />
                </div>
            </ConfigProvider>
        </div>
    )
}

export default UiDatePicker
