import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ISelectOption } from '../../app/types/common'
import { PriorityTypes, RoadMapDetalizationTypes } from '../../app/types/enums'
import {
    ITask,
    ITasksFilterParams,
    SortTaskTypes
} from '../../app/types/models/task'
import EditTaskModal from '../../entities/editTaskModal/EditTaskModal'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { tagsServices } from '../../services/tags-services'
import { tasksService } from '../../services/tasks-services'
import { Pagination } from '../../shared/Pagination/Pagination'
import { UiAccordion } from '../../shared/ui/accordion/UiAccordion'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { tasksActions } from '../../store/tasks'
import {
    addTask,
    deleteTask,
    setTasks,
    setTaskStatus,
} from '../../store/tasks/actions'
import { selectTaskData, selectTaskStatus } from '../../store/tasks/selectors'
import { getCorrectDateFormat } from '../../utils/getCorrectDateFormat'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../utils/helpers/showNotice'
import { TASKS_LIMIT, TASKS_LIMIT_MAX } from './../../app/constants'
import RoadMapTasks from './components/roadMapTasks/RoadMapTasks'
import RoadMapTop from './components/roadMapTop/RoadMapTop'
import { initialFilterData } from './data'
import { RoadmapContext } from './providers'
import './roadMap.css'
import { IUpdateTaskModalData } from './types'

const RoadMap = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const { results, count } = useSelector(selectTaskData)
    const tasksStatus = useSelector(selectTaskStatus)
    const [sortParams, setSortParams] = useState<SortTaskTypes[]>([])
    const [tagsOptions, setTagsOptions] = useState<ISelectOption[]>([])
    const [filterParams, setFilterParams] =
        useState<ITasksFilterParams>(initialFilterData)
    const [isCollapsed, setIsCollapsed] = useState(true)
    const [detalizationData, setDetalizationData] = useState({
        detalization: RoadMapDetalizationTypes.MONTH,
        startPeriod: '',
        endPeriod: '',
    })
    const [updateTaskModalData, setUpdateTaskModalData] =
        useState<IUpdateTaskModalData>({
            isOpen: false,
            task: undefined,
        })

    const getCarlListLimit = (status?: boolean) => {
        if (status === undefined) {
            return isCollapsed ? TASKS_LIMIT : TASKS_LIMIT_MAX
        } else {
            return status ? TASKS_LIMIT : TASKS_LIMIT_MAX
        }
    }

    const getTags = useCallback(async () => {
        try {
            const response = await tagsServices.getTags(currentProject?.id)
            if (
                response &&
                response?.results &&
                response?.results?.length > 0
            ) {
                setTagsOptions(
                    response.results.map((el) => ({
                        label: el?.name,
                        value: el?.id,
                    })),
                )
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }, [currentProject])

    useEffect(() => {
        if (
            results &&
            results.length > 0 &&
            updateTaskModalData?.task?.id !== undefined
        ) {
            const targetTask = results.find(
                (el) => el?.id === updateTaskModalData?.task?.id,
            )
            if (targetTask) {
                setUpdateTaskModalData((prev) => ({
                    ...prev,
                    task: targetTask,
                }))
            }
        }
    }, [results])

    const createTask = async (name: string, priority: PriorityTypes) => {
        try {
            const dateNow = new Date()

            const res = await tasksService.createTask(
                {
                    name: name.trim(),
                    priority,
                    due_date_start: getCorrectDateFormat({ date: new Date() })
                        .split('-')
                        .reverse()
                        .join('-'),
                    due_date_end: getCorrectDateFormat({
                        date: new Date(dateNow.setDate(dateNow.getDate() + 7)),
                    })
                        .split('-')
                        .reverse()
                        .join('-'),
                },
                currentProject?.id,
            )

            const task = {
                ...res,
            }
            dispatch(
                addTask({
                    task,
                }),
            )
            showNotify(t('task-added-successfully'))
            setUpdateTaskModalData({ isOpen: true, task: res as ITask })
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    const removeTask = async (item: ITask): Promise<any> => {
        try {
            await tasksService.deleteTask(currentProject?.id, item?.slug)
            dispatch(deleteTask({ slug: item?.slug }))
            showNotify(t('task-deleted-successfully'))
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    const handleChangePagination = async (params: {
        limit: number
        offset: number
    }) => {
        setFilterParams((prev) => ({ ...prev, ...params }))
    }

    const getTasks = async (params?: any) => {
        try {
            dispatch(setTaskStatus('loading'))
            let resultParams = { ...filterParams }
            if (params) {
                for (let key in params) {
                    if (params[key] === '') {
                        delete params[key]
                    }
                }
                resultParams = { ...resultParams, ...params }
            }
            const response = await tasksService.getTasks(currentProject?.id, {
                ...resultParams,
                is_template:false,
                tags:
                    filterParams?.tags && filterParams?.tags?.length > 0
                        ? filterParams?.tags.join(',')
                        : undefined,
                doers__user:
                    filterParams?.doers__user &&
                    filterParams?.doers__user?.length > 0
                        ? filterParams?.doers__user.join(',')
                        : undefined,
            })
            dispatch(setTasks(response))
            dispatch(setTaskStatus('succeeded'))
            if (tagsOptions.length === 0) {
                getTags()
            }
        } catch (error) {
            errorsHandler(error, t)
            dispatch(setTaskStatus('failed'))
        }
    }

    const handleCloseAccordion = (isCollapsedArg) => {
        if (results && results.length >= 20) {
            setFilterParams((prev) => ({
                ...prev,
                limit: getCarlListLimit(isCollapsedArg),
            }))
        }
        setIsCollapsed(!isCollapsed)
    }

    const closeUpdateTaskModal = useCallback(() => {
        setUpdateTaskModalData({ isOpen: false, task: undefined })
    }, [setUpdateTaskModalData])

    useLayoutEffect(() => {
        currentProject?.id !== undefined && getTasks({})
    }, [filterParams, currentProject])

    useEffect(() => {
        if (updateTaskModalData?.task && updateTaskModalData.isOpen) {
            dispatch(
                tasksActions.getComments({
                    projectId: currentProject?.id,
                    slug: updateTaskModalData?.task?.slug,
                }),
            )
        }
    }, [updateTaskModalData.isOpen])

    useEffect(() => {
        if (updateTaskModalData.isOpen && updateTaskModalData.task && results) {
            const targetTask = results.find(
                (el) => el.slug === updateTaskModalData.task?.slug,
            )
            if (targetTask) {
                setUpdateTaskModalData((prev) => ({
                    ...prev,
                    task: targetTask,
                }))
            }
        }
    }, [results])

    return (
        <RoadmapContext.Provider
            value={{
                sortParams,
                tagsOptions,
                filterParams,
                detalizationData,
                updateTaskModalData,

                removeTask,
                createTask,
                setSortParams,
                setTagsOptions,
                setFilterParams,
                setDetalizationData,
                setUpdateTaskModalData,
            }}
        >
            <div className="roadMap">
                <div className="roadMap-inner">
                    <UiAccordion
                        className="roadmap-accordion"
                        title={t('filters')}
                        activeStatus={true}
                        handleCollapse={handleCloseAccordion}
                    >
                        <RoadMapTop />
                    </UiAccordion>
                    <RoadMapTasks
                        isLoading={tasksStatus === 'loading'}
                        isCollapsed={isCollapsed}
                    />
                    <div className="roadMap-pagination">
                        <Pagination
                            count={count}
                            limit={getCarlListLimit()}
                            onChange={handleChangePagination}
                        />
                    </div>
                </div>
            </div>
            {updateTaskModalData.isOpen && tasksStatus !== 'loading' && (
                <EditTaskModal
                    isOpen={updateTaskModalData.isOpen}
                    type="task"
                    data={{
                        ...updateTaskModalData.task,
                    }}
                    projectId={updateTaskModalData.task?.project}
                    closeModal={closeUpdateTaskModal}
                />
            )}
        </RoadmapContext.Provider>
    )
}

export { RoadMap }

