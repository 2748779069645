import { Tabs, Tag } from 'antd'
import { TweenOneGroup } from 'rc-tween-one'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { tagsServices } from '../../services/tags-services'
import { tasksService } from '../../services/tasks-services'
import { updateTask } from '../../store/tasks/actions'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { showNotice, showNotify } from '../../utils/helpers/showNotice'
import { TagsList } from '../TagsList/TagsList'
import { SystemTagItem } from '../TagsList/types'
import ModalInlog from '../modalInlog/ModalInlog'
import UiInput from '../ui/input/UiInput'
import './TagsDropdown.css'
import { renderSelectedTags } from './services/render-selected-tags'
import { TagsTypes } from './types'
import { ITag } from '../../app/types/models/task'

export const TagsDropdown = ({
    projectId,
    slug,
    taskId,
    createTag,
    tags,
}: {
    projectId: number
    slug: string
    taskId: number
    tags?: ITag[]
    createTag?: (data: ITag) => void
}) => {
    const dispatch = useAppDispatch()
    const [selectedTags, setSelectedTags] = useState<SystemTagItem[]>([])
    const [fetchedTags, setFetchedTags] = useState<SystemTagItem[]>([])
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const { t } = useTranslation()
    const [collapsed, setCollapsed] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const inputRef = useRef(null)
    const [currentTab, setCurrentTab] = useState<TagsTypes>(TagsTypes.RESENT)

    //загружаю из апи полный список тегов для этого проекта
    const fetchTags = useCallback(async () => {
        try {
            if (projectId) {
                const response = await tagsServices.getTags(projectId,currentTab===TagsTypes.ARCHIVE)
                setFetchedTags(response?.results)
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }, [projectId,currentTab])

    const deleteTags = useCallback(
        async (tagIds: number[]) => {
            try {
                if (fetchedTags) {
                    const filteredTags = selectedTags.filter(
                        (item) => !tagIds.includes(item?.id),
                    )
                    const preparedTags = filteredTags ? filteredTags : []
                    const tagsIds = preparedTags.map((item) => item?.id)
                    await tasksService.updateTask(
                        { tags: tagsIds },
                        projectId,
                        slug,
                    )
                    dispatch(
                        updateTask({
                            id: taskId,
                            tags: filteredTags,
                        }),
                    )
                    setSelectedTags(filteredTags)
                    if (tagIds.length === 1) {
                        showNotice('tag-deleted-successfully', t)
                    } else {
                        showNotice('all-tags-deleted-successfully', t)
                    }
                }
            } catch (e) {
                errorsHandler(e, t)
            }
        },
        [selectedTags, fetchedTags],
    )

    const showInput = () => {
        setInputVisible(true)
    }

    //инпут для ввода названия кастомного тега
    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const addTagData = useCallback(
        async (data) => {
            if (data && Array.isArray(data)) {
                try {
                    const preparedTagIds = data.map((item) => item?.id)
                    await tasksService.updateTask(
                        { tags: preparedTagIds },
                        projectId,
                        slug,
                    )
                    dispatch(
                        updateTask({
                            id: taskId,
                            tags: data,
                        }),
                    )
                    showNotify(t('tag-added-successfully'))
                } catch (e) {
                    errorsHandler(e, t)
                }
            }
        },
        [slug, taskId, projectId],
    )

    //создаю новый тег в базе и сразу же добавляю его запросом в таску. Для системных тегов
    const createTagData = async (name: string) => {
        try {
            const targetTag = fetchedTags.find((el) => el.name === name)
            if (fetchedTags && !targetTag) {
                const response = await tagsServices.createTag(projectId, {
                    name,
                })
                await addTagData(
                    selectedTags ? [...selectedTags, response] : [response],
                )
                createTag && createTag(response)
                const result = selectedTags
                    ? [...selectedTags, response]
                    : [response]
                setSelectedTags(result)
                dispatch(
                    updateTask({
                        id: taskId,
                        tags: selectedTags
                            ? [...selectedTags, response]
                            : [response],
                    }),
                )
            } else if (fetchedTags && targetTag) {
                const isTagExist = selectedTags.find((tag) => tag.name === name)
                if (isTagExist) {
                    showNotify(t('tag-with-name-already-existed'), {
                        type: 'error',
                    })
                } else {
                    await addTagData(
                        selectedTags
                            ? [...selectedTags, { ...targetTag }]
                            : [targetTag],
                    )
                    const result = selectedTags
                        ? [...selectedTags, { ...targetTag }]
                        : [targetTag]

                    setSelectedTags(result)
                    dispatch(
                        updateTask({
                            id: taskId,
                            tags: result,
                        }),
                    )
                }
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const addTags = async (list: string[]) => {
        try {
            const newTags: string[] = []
            const existsTags: SystemTagItem[] = []

            list.forEach((listEl) => {
                const targetTag = fetchedTags.find(
                    (tagEl) => tagEl.name === listEl,
                )
                if (targetTag) {
                    existsTags.push(targetTag)
                } else {
                    newTags.push(listEl)
                }
            })
            if (newTags.length > 0) {
                for (let i = 0; i < newTags.length; i++) {
                    const name = newTags[i]
                    const res = await tagsServices.createTag(projectId, {
                        name,
                    })
                    createTag && createTag(res)
                    existsTags.push(res)
                }
            }

            const tagsIds = new Set([
                // ...selectedTags,
                ...existsTags.map((el) => el.id),
            ])
            await tasksService.updateTask(
                {
                    tags: [
                        ...Array.from(tagsIds),
                        ...selectedTags.map((el) => el?.id),
                    ],
                },
                projectId,
                slug,
            )

            const filteredTagsForSelectedList = existsTags.filter(
                (el) => !selectedTags.find((val) => val?.id === el?.id),
            )
            const result = [...selectedTags, ...filteredTagsForSelectedList]
            setSelectedTags(result)
            dispatch(
                updateTask({
                    id: taskId,
                    tags: result,
                }),
            )
            if (list.length === 1) {
                showNotify(t('tag-added-successfully'))
            } else {
                showNotify(t('all-tags-added-successfully'))
            }
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    //при кастомном вводе тега при нажатии на enter происходит создание тега и добавления его в список тегов текущей таски
    const handleInputConfirm = async () => {
        if (inputValue && inputValue.length <= 15) {
            await createTagData(inputValue)
        } else if (inputValue && inputValue.length > 15) {
            showNotify(t('max-symblos-length-message', { value: 15 }), {
                type: 'error',
            })
        }
        setInputVisible(false)
        setInputValue('')
    }

    const tagChild = selectedTags
        ? selectedTags
              .filter((f) => f.name !== '')
              .map((tag, i) => renderSelectedTags(tag, i, deleteTags))
        : ''
    const toggleCollapseHandler = () => {
        setCollapsed(!collapsed)
    }

    //возвращаю последние 6 тегов, которые были созданы для этого проекта
    const getLimitedTags = useMemo(() => {
        if (fetchedTags && fetchedTags.length <= 6) {
            return fetchedTags
        } else if (fetchedTags && fetchedTags.length > 6) {
            //возвращаю 6 последних тегов, которые пришли с апи
            return fetchedTags.slice(fetchedTags.length - 6, fetchedTags.length)
        } else return null
    }, [fetchedTags, currentTab])

    const showTabItems = useCallback(() => {
        return [TagsTypes.RESENT, TagsTypes.SYSTEM, TagsTypes.ARCHIVE].map(
            (tag, i) => ({
                label: t(tag).toString(),
                key: tag,
                children: (
                    <TagsList
                        key={currentTab}
                        currentTab={currentTab}
                        selectedTags={selectedTags}
                        fetchedTags={
                            currentTab === TagsTypes.RESENT
                                ? getLimitedTags
                                : fetchedTags
                        }
                        deleteTags={deleteTags}
                        addTags={addTags}
                    />
                ),
            }),
        )
    }, [currentTab, deleteTags, addTags])

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [])

    useEffect(() => {
        if (
            taskId !== undefined &&
            [TagsTypes.RESENT, TagsTypes.ARCHIVE].includes(currentTab)
        ) {
            fetchTags()
        }
    }, [selectedTags, currentTab])

    useEffect(() => {
        if (tags && tags?.length > 0) {
            setSelectedTags(tags)
        } else {
            setSelectedTags([])
        }
    }, [tags])

    console.log('fetchedTags', fetchedTags)

    return (
        <div>
            <div className="tagsDropdown-top">
                <div className="tagsDropdown-top-tagSpace">
                    <TweenOneGroup appear={false}>{tagChild}</TweenOneGroup>
                </div>
                <div
                    className="tagsDropdown-inputSpace"
                    onClick={toggleCollapseHandler}
                >
                    {inputVisible && (
                        <UiInput
                            ref={inputRef}
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputConfirm}
                            className="tagsDropdown__input"
                            onPressEnter={handleInputConfirm}
                        />
                    )}
                    {!inputVisible && (
                        <Tag onClick={showInput} className="cursor-pointer">
                            <div className="tagsDropdown-add-tag-btn-wrapper">
                                <span>{t('newTag')}</span>
                                <PlusIcon circle={false} />
                            </div>
                        </Tag>
                    )}
                    <button
                        className={'cursor-pointer tagsDropdown-btn'}
                        onClick={() => setIsOpenModal(!isOpenModal)}
                    >
                        <PlusIcon circle={false} />
                    </button>
                </div>
                <ModalInlog
                    className="scheduler-tags-modal"
                    title={t('tagsList')}
                    open={isOpenModal}
                    onCancel={() => {
                        setIsOpenModal(false)
                    }}
                    footer={null}
                >
                    <div className="scheduler-tags-modal-inner">
                        <div className="tabs-list">
                            <Tabs
                                className="scheduler-tags-tabs"
                                onChange={(activeKey) =>
                                    setCurrentTab(activeKey as TagsTypes)
                                }
                                items={showTabItems()}
                            />
                        </div>
                    </div>
                </ModalInlog>
            </div>
        </div>
    )
}
