import { ITask } from "./task"

export interface IPanel {
    id: number
    name: string
    position: string | number
    tasks: ITask[]
    tasksOrder: string[]
}

export enum SortPanelTasksTypes {
    BY_NAME='by-name',
    BY_CREATION_DATE='by-cretion-date',
    BY_LOW_TO_CRITICAL_PRIORITY='by-low-to-critical-priority',
    BY_CRITICAL_TO_LOW_PRIORITY='by-critical-to-low-priority',
}