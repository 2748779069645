import React from 'react'
import { useTranslation } from 'react-i18next'
import UiTabs from '../../shared/tabs/Tabs'
import ProfileTab from './components/ProfileTab/ProfileTab'
import './userProfile.css'

const UserProfile = () => {
    const { t } = useTranslation()

    const tabsItems = [
        {
            key: '0',
            label: t('employee'),
            children: <ProfileTab />,
        },
        {
            key: '1',
            label: t('methods'),
            children: <>methods</>,
        },
        {
            key: '2',
            label: t('education'),
            children: <>education</>,
        },
        {
            key: '3',
            label: t('medical-examinations'),
            children: <>edical-examinations</>,
        },
        {
            key: '4',
            label: t('quality-measures'),
            children: <>Quality measures</>,
        },
    ]

    return (
        <div className="profile-inner">
            <UiTabs items={tabsItems} />
        </div>
    )
}

export default UserProfile
