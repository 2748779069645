import React from 'react'
import { useTranslation } from 'react-i18next'
import { IComment } from '../../../../app/types/models/task'
import { AddTextButton } from '../../../../shared/AddTextButton/AddTextButton'
import { getCorrectDateFormat } from '../../../../utils/getCorrectDateFormat'
import { CommentItem } from '../CommentItem/CommentItem'
import './comment.css'

const Comments = ({ comments,onSave }:{comments:IComment[],onSave:(value:string)=>void}) => {
    const { t } = useTranslation()

    const handleSave = (value) => {
        onSave(value)
    }

    return (
        <div>
            <AddTextButton
                addText={handleSave}
                placeholder={t('enter-comment')}
                title={t('comment')}
            />
            <div className="scheduler__comment-list">
                {comments.length > 0 &&
                    comments?.map((c) => {
                        return (
                            <CommentItem
                                key={c?.id}
                                userId={c?.user?.id}
                                text={c?.text}
                                name={c?.user?.full_name}
                                date={getCorrectDateFormat({date:c?.created_at})}
                            />
                        )
                    })}
            </div>
        </div>
    )
}

export { Comments as Comment }
