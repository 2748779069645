import {IMember, IUser} from "../../../app/types/models/user";
import {RoleTypes} from "../../../app/types/enums";

export const getValidRoleOptions = (me:IUser,member:IMember,t:Function)=>{
    //1) me - admin
    if(me?.role===RoleTypes.admin){
        if(member?.role===RoleTypes.admin){
            return (
                [
                    {
                        label: t('creator').toString(),
                        value: 'admin'
                    },
                    {
                        label: t('leave-project').toString(),
                        value: 'leave_project'
                    },
                ]
            )
        }else{
            return (
                [
                    {
                        label: t('member').toString(),
                        value: 'member'
                    },
                    {
                        label: t('editor').toString(),
                        value: 'editor'
                    },
                    {
                        label: t('close-access').toString(),
                        value: 'close_access'
                    },
                    {
                        label: t('transfer-rights').toString(),
                        value: 'transfer_rights'
                    },
                ]
            )
        }
    }else{
        if(member?.role===RoleTypes.admin){
            return []
        }else{
            return [
                {
                    label: t('member').toString(),
                    value: 'member'
                },
                {
                    label: t('editor').toString(),
                    value: 'editor'
                },
                {
                    label: t('leave-project').toString(),
                    value: 'leave_project'
                },
            ]
        }
    }
}