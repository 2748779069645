import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { ITask } from '../../app/types/models/task'
import { tasksService } from '../../services/tasks-services'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import {
    addSubtask,
    addTask,
    deleteSubtask,
    deleteTask,
    updateTask,
} from './actions'

const addNewSubtask = createAsyncThunk<
    void,
    {
        taskId: number
        projectId: number
        value: string
        is_template?: boolean
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/addNewSubtaskAsync',
    async (
        { taskId, value, projectId, is_template, onSuccess, onError },
        thunkAPI: any,
    ) => {
        try {
            const newSubTask = {
                name: value.trim(),
                parent: taskId,
                is_template,
            }
            const res = await tasksService.createSubTask(newSubTask, projectId)
            thunkAPI.dispatch(addSubtask({ subtask: res }))
            thunkAPI.dispatch(addTask({ task: res }))

            const tasks = thunkAPI.getState().tasks?.results
            if (tasks && tasks.length > 0) {
                const targetTask = tasks.find((el: ITask) => el.id === taskId)

                if (targetTask) {
                    let resultList = []
                    if (targetTask.subtasks) {
                        resultList = targetTask.subtasks
                    }
                    if (!is_template) {
                        thunkAPI.dispatch(
                            updateTask({
                                slug: targetTask?.slug,
                                subtasks: [res, ...resultList],
                                is_template,
                            }),
                        )
                    }
                }
            }

            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)
const removeSubtask = createAsyncThunk<
    void,
    {
        slug: string
        parentTaskSlug: string
        projectId: number
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/removeSubtaskAsync',
    async (
        { slug, parentTaskSlug, projectId, onSuccess, onError },
        thunkAPI: any,
    ) => {
        try {
            await tasksService.deleteTask(projectId, slug)

            const tasks = thunkAPI.getState().tasks?.results
            if (tasks && tasks.length > 0) {
                const targetTask = tasks.find(
                    (el: ITask) => el.slug === parentTaskSlug,
                )

                if (targetTask) {
                    let resultList = []
                    if (targetTask.subtasks) {
                        resultList = targetTask.subtasks.filter(
                            (el) => el?.slug !== slug,
                        )
                    }
                    thunkAPI.dispatch(
                        updateTask({
                            slug: parentTaskSlug,
                            subtasks: resultList,
                        }),
                    )
                }
            }
            thunkAPI.dispatch(deleteSubtask({ slug }))
            thunkAPI.dispatch(deleteTask({ slug }))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)
const changeSubtaskTitle = createAsyncThunk<
    void,
    {
        slug: string
        value: string
        parentTaskId: number
        projectId: number
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/removeSubtaskAsync',
    async (
        { slug, projectId, value, parentTaskId, onSuccess, onError },
        thunkAPI: any,
    ) => {
        try {
            const updatedSubTask = { name: value, parent: parentTaskId }
            const res = await tasksService.updateSubTask(
                updatedSubTask,
                projectId,
                slug,
            )

            const tasks = thunkAPI.getState().tasks?.results
            const currentTask = thunkAPI.getState()?.tasks?.currentTask
            if (tasks && tasks.length > 0) {
                const targetTask = tasks.find(
                    (el: ITask) => el.id === parentTaskId,
                )

                if (targetTask) {
                    let resultList = []
                    if (targetTask.subtasks) {
                        resultList = targetTask.subtasks.map((el) =>
                            el?.slug === slug ? res : el,
                        )
                    }
                    thunkAPI.dispatch(
                        updateTask({
                            slug: targetTask?.slug,
                            subtasks: resultList,
                        }),
                    )
                    thunkAPI.dispatch(updateTask({ slug, name: value }))
                }
            }

            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

export const asyncSubtasksActions = {
    addNewSubtask,
    removeSubtask,
    changeSubtaskTitle,
}
