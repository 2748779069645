import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/redux-hooks'
import PanelRoadmapIcon from '../../image_files/icons/PanelRoadmapIcon'
import PanelStatusesIcon from '../../image_files/icons/PanelStatusesIcon'
import PanelTasksIcon from '../../image_files/icons/PanelTasksIcon'
import TeamIcon from '../../image_files/icons/TeamIcon'
import { setSchedulerPage } from '../../store/app/actions'
import { selectSchedulerPage } from '../../store/app/selectors'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { selectCurrentOrganization } from '../../store/projects/selectors'
import { getUrlChunks } from '../../utils/urlHelpers'
import { SchedulerPagesType } from '../../widgets/scheduler/types'

interface ISchedulerMenuFeature {
    path: SchedulerPagesType
    title: string
}

const SchedulerMenuFeatures = ({ collapsed }: { collapsed: boolean }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const schedulerPage = useSelector(selectSchedulerPage)
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)

    const features = [
        { title: t('tasks'), path: 'tasks' },
        { title: t('statuses'), path: 'statuses' },
        // { title: t('scheduler.navbar.equipment'), path: 'equipment' },
        // { title: t('scheduler.navbar.activity'), path: 'activity' },
        { title: t('roadmap'), path: SchedulerPagesType.roadMap },
        { title: t('team'), path: 'team' },
    ]

    const handleSelectPage = (path: SchedulerPagesType) => {
        dispatch(setSchedulerPage(path))
    }

    useEffect(() => {
        if(currentOrganization && currentProject?.id && schedulerPage){
        if (schedulerPage === SchedulerPagesType.tasks) {
            navigate(
                `${currentOrganization}/${currentProject?.id}/${schedulerPage}/items`,
            )
        } else {
            navigate(
                `${currentOrganization}/${currentProject?.id}/${schedulerPage}`,
            )
        }
    }

    }, [schedulerPage])

    return (
        <ul className="mt-20 column-flex">
            {features.map((f: ISchedulerMenuFeature) => {
                return (
                    <div
                        key={f?.path}
                        onClick={() => handleSelectPage(f?.path)}
                    >
                        <SchedulerMenuFeature collapsed={collapsed} item={f} />
                    </div>
                )
            })}
        </ul>
    )
}

const SchedulerMenuFeature = ({
    item,
    collapsed,
}: {
    item: ISchedulerMenuFeature
    collapsed: boolean
}) => {
    const urlChunks = getUrlChunks()
    
    const menuItemClass = 'p-8 transition1 font-14-normal cursor-pointer br-default'

    const getValidClass = (item: ISchedulerMenuFeature) => {
        let status = false
        if (item?.path === urlChunks[3]) {
            status = true
        }

        if (collapsed) {
            return `${menuItemClass} scheduler__menu-feature-collapsed ${
                status ? 'menu-item--selected' : ''
            }`
        } else {
            return `${menuItemClass} ${
                status ? 'menu-item--selected' : ''
            }`
        }
    }

    const getValidIcon = () => {
        switch (item.path) {
            case SchedulerPagesType.tasks:
                return <PanelTasksIcon className="flex-shrink-0" />
            case SchedulerPagesType.statuses:
                return <PanelStatusesIcon className="flex-shrink-0" />
            case SchedulerPagesType.roadMap:
                return <PanelRoadmapIcon className="flex-shrink-0" />
            case SchedulerPagesType.team:
                return <TeamIcon className="flex-shrink-0" />
            default:
                return <></>
        }
    }

    return (
        <li className={getValidClass(item)}>
            {collapsed ? getValidIcon() : <span>{item?.title}</span>}
        </li>
    )
}

export default SchedulerMenuFeatures
