import { createAction } from '@reduxjs/toolkit'
import { IUser } from '../../app/types/models/user'
import { LanguageTypes } from '../../app/types/enums'
import { RequestStatusType } from '../../app/types/request'

const setUser = createAction<{ user: IUser }>('userActions/setUser')

const setLanguage = createAction<{ language: LanguageTypes }>(
    'userActions/setLanguage',
)
const setUserStatus = createAction<RequestStatusType>(
    'userActions/setUserStatus',
)
const setUserFilesStatus = createAction<RequestStatusType>(
    'userActions/setUserFilesStatus',
)

export { setUser, setLanguage,setUserStatus,setUserFilesStatus }
