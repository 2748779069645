import React, {
    memo,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { ITask, ITasksFilterParams } from '../../app/types/models/task'
import { TasksTabTypes } from '../../features/tasks/types'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { selectCurrentOrganization } from '../../store/projects/selectors'
import { tasksActions } from '../../store/tasks'
import TasksList from './TasksList'
import TasksFilter from '../../features/tasks/components/TasksFilter/TasksFilter'
import { selectTaskData, selectTaskStatus } from '../../store/tasks/selectors'
import { useTranslation } from 'react-i18next'
import { getValidText } from '../../utils/helpers/getValidText'

const TasksContainer = ({
    type,
    className,
    getTasks,
    params,
}: {
    type: TasksTabTypes
    className?: string
    params:any
    getTasks: (params: ITasksFilterParams) => void
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)
    const { results: tasks, count: tasksCount } = useSelector(selectTaskData)
    const tasksStatus = useSelector(selectTaskStatus)

    const [filterParams, setFilterParams] = useState<ITasksFilterParams>({...params})

    const isHiddenContent = tasksStatus === 'succeeded' && tasks.length === 0

    const getTask = 
        (task: ITask) => {
            // debugger
            // if (location.state?.slug !== task?.slug) {
                dispatch(
                    tasksActions.getCurrentTask({
                        slug: task?.slug,
                        onSuccess: () => {
                            navigate(
                                `/scheduler/${currentOrganization}/${
                                    currentProject?.id
                                }/tasks/${
                                    type === TasksTabTypes.TASKS
                                        ? 'items'
                                        : 'templates'
                                }/${task?.slug}`,
                                {
                                    state: {
                                        slug: task?.slug,
                                    },
                                },
                            )
                        },
                    }),
                )
            // }
        }

    const handleChangePagination = async (params: {
        limit: number
        offset: number
    }) => {
        setFilterParams((prev) => ({ ...prev, ...params }))
    }

    // useLayoutEffect(()=>{
    //     setFilterParams(params)
    // },[params])

    useLayoutEffect(() => {
        if (currentProject?.id && currentOrganization !== undefined) {
            getTasks(filterParams)
        }
    }, [filterParams])
    
    return (
        <div className={getValidText(className)}>
            {isHiddenContent && (
                <div className="font-14-normal scheduler__absent-task-message">
                    {type === TasksTabTypes.TASKS
                        ? t('tasks-absent-message')
                        : t('templates-absent-message')}
                </div>
            )}
            <div className={`${isHiddenContent ? 'display-none' : ''}`}>
                <TasksFilter
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                />
                <TasksList
                    type={type}
                    getTask={getTask}
                    changePagination={handleChangePagination}
                />
            </div>
        </div>
    )
}

export default TasksContainer
