import { Skeleton } from 'antd';
import React from 'react';

const propTypes = {};

const ProfileTabFormSkeleton = () => {
    return <div className='profile__form-fields'>
        {Array(15).fill('').map((el,i)=>(
                <Skeleton.Input active className='profile__form-skeleton' />
        ))}
    </div>;
}



export default ProfileTabFormSkeleton;