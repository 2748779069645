import { Skeleton } from 'antd'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TASKS_LIMIT } from '../../app/constants'
import { ITask } from '../../app/types/models/task'
import { TasksTabTypes } from '../../features/tasks/types'
import { Pagination } from '../../shared/Pagination/Pagination'
import { selectTaskData, selectTaskStatus } from '../../store/tasks/selectors'
import { Task } from '../Task/Task'

const TasksList = ({
    type,
    getTask,
    changePagination,
}: {
    type:TasksTabTypes
    getTask:(task:ITask)=>void
    changePagination: (data: { limit: number; offset: number }) => void
}) => {
    const { t } = useTranslation()
    const tasksStatus = useSelector(selectTaskStatus)
    const { results: tasks, count: tasksCount } = useSelector(selectTaskData)

    const showSkeleton = () =>
        Array(4)
            .fill('')
            .map((_, i) => <TaskSkeletonItem key={i} />)

    const showContainer = () => {
        let isShowItems = true
        if (tasksStatus === 'loading') {
            isShowItems = false
        }

        if (isShowItems) {
            return <div className="scheduler__tasks-container">{items}</div>
        } else {
            return (
                <div className="scheduler__tasks-skeleton-wrapper">
                    {showSkeleton()}
                </div>
            )
        }
    }

    const items = useMemo(() => {
        return tasks.map((el) => (
            <Task
                task={el}
                key={el?.id}
                onClick={()=>getTask(el)}
                type={type}
            />
        ))
    }, [tasks, type])

    return (
        <>
            <div className="mt-10 br-default bg-white">{showContainer()}</div>
                <Pagination
                    count={tasksCount}
                    limit={TASKS_LIMIT}
                    onChange={changePagination}
                />
        </>
    )
}

const TaskSkeletonItem = () => {
    return (
        <Skeleton.Input
            active={true}
            className="scheduler__tasks-skeleton-item"
        />
    )
}

export default TasksList
